<template>
  <div class="pdf-doc">
    <div class="pdf-view">
      <!-- Ficha Tecnica -->
      <section class="pdf" v-for="Page in Pages">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo" />
          </div>
          <div class="title-container">
            <div>
              <strong>
                {{  }}
              </strong>
            </div>
            <div>
              <strong>
                {{  }}
              </strong>
            </div>
            <div>
              <strong>
                {{  }}
              </strong>
            </div>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="Preview" v-if="Preview">
            {{ $t('button.preview') }}
          </div>
          <div class="general-info">
            <div class="info-content">
              <div class="title-report">
                <table class="title-table">
                  <tbody>
                    <tr>
                      <td class="limit-field-16 text-size-12">
                        <strong>{{ `${$t('label.line')}: ` }} {{infoContainer?.ShippingLineName}} </strong>
                        {{ }}
                      </td>
                      <td class="limit-field-16">
                      </td>
                      <td class="text-center">
                        <strong style="font-size: medium;">{{ $t('label.EquipmentInterchangeReceipt') }}</strong>
                      </td>
                      <td style class="limit-field-16">
                      </td>
                      <td class="limit-field-16 text-size-12">
                        <strong class="color-eir">N° {{infoContainer?.YardCargoEirCode}} </strong>
                      </td>
                     </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="header-info mt-2">
                <table class="header-table text-center">
                  <tbody>
                    <tr>
                      <th>{{ $t('label.date') }}</th>
                      <th>{{ $t('label.hour') }}</th>
                      <th rowspan="2" colspan="3">{{ $t('label.equipment') }} </th>
                      <td rowspan="2" colspan="6"> <b>{{infoContainer?.ContainerCode}}</b></td>
                      <th colspan="2">{{ $t('label.Delivered') }}</th>
                      <th colspan="2">{{ $t('label.received') }}</th>
                      <th>{{ $t('label.deliverySector') }}</th>
                    </tr>
                    <tr>
                      <td rowspan="2">{{ DateFormat2(FormatedDate)}}</td>
                      <td rowspan="2">{{ DateFormat3(FormatedDate)}}</td>
                      <th>{{ $t('label.full') }}</th>
                      <th>{{ $t('label.empty') }}</th>
                      <th>{{ $t('label.full') }}</th>
                      <th>{{ $t('label.empty') }}</th>
                      <td>{{infoContainer?.YardName}}</td>
                    </tr>
                    <tr class="text-center">
                      <td> 
                        <div><b class="mr-2" v-if="infoContainer?.Size == '20'">x</b>20</div>
                      </td>
                      <td> 
                        <div><b class="mr-2" v-if="infoContainer?.Size == '40'">x</b>40</div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.Size == '45'">x</b>45</div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.TpCargoCode == 'ST'">x</b>ST</div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.TpCargoCode == 'HC'">x</b>HC</div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.TpCargoCode == 'OT'">x</b>OT</div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.TpCargoCode == 'FT'">x</b>FT</div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.TpCargoCode == 'RF'">x</b>RF</div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.TpCargoCode == 'TK'">x</b>TK</div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.EventId == validateReception &&
                          infoContainer?.TpCargoStatusName == 'FULL'">x</b>
                        </div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.EventId == validateReception && 
                          infoContainer?.TpCargoStatusName == 'EMPTY'">x</b>
                        </div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.EventId == validateDispatch && 
                          infoContainer?.TpCargoStatusName == 'FULL'">x</b>
                        </div>
                      </td>
                      <td>
                        <div><b class="mr-2" v-if="infoContainer?.EventId == validateDispatch && 
                          infoContainer?.TpCargoStatusName == 'EMPTY'">x</b>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="bl-info mt-2">
                <table class="bl-table text-table-center">
                  <tbody>
                    <tr>
                      <th>BL N°</th>
                      <td>{{infoContainer?.BlNro }}</td>
                      <th>{{ $t('label.gross') }}</th>
                      <td>{{formatNumber(infoContainer?.Weigth)}}</td>
                      <th>{{ $t('label.FullsSeal') }} N°</th>
                      <td> 
                        <b>S1:</b> {{ infoContainer?.Seal1 ? infoContainer?.Seal1 : '' }}
                        <b>S2:</b> {{ infoContainer?.Seal2 ? infoContainer?.Seal2 : '' }} 
                        <b>S3:</b> {{ infoContainer?.Seal3 ? infoContainer?.Seal3 : '' }} 
                        <b>S4:</b> {{ infoContainer?.Seal4 ? infoContainer?.Seal4 : '' }} 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="Seal-info text-table-left mt-2">
                <strong>{{ $t('label.SealShippingLineOrCustomBroker') }}</strong>
                <table class="Seal-table-general">
                  <tbody>
                    <tr>
                      <td rowspan="2" colspan="2">
                        <div style="width: 80%;" class="py-1">
                          <table class="Seal-table text-table-center">
                            <tr>
                              <th>P1</th>
                              <td v-for="index in 11">{{ infoContainer?.Seal1 ? infoContainer?.Seal1[index-1] : ''}}</td>
                            </tr>
                            <tr>
                              <th>P2</th>
                              <td v-for="index in 11">{{ infoContainer?.Seal2 ? infoContainer?.Seal2[index-1] : ''}}</td>
                            </tr>
                            <tr>
                              <th>P3</th>
                              <td v-for="index in 11">{{ infoContainer?.Seal3 ? infoContainer?.Seal3[index-1] : ''}}</td>
                            </tr>
                            <tr>
                              <th>P4</th>
                              <td v-for="index in 11">{{ infoContainer?.Seal4 ? infoContainer?.Seal4[index-1] : ''}}</td>
                            </tr>
                          </table>
                        </div>
                      </td>
                      <td colspan="2"><b>{{ $t('label.SealCustomBroker') }}:</b> {{ infoContainer?.SealCustom ?? '' }}</td>
                    </tr>
                    <tr>
                      <td colspan="2"><b>BOOKING N°:</b> {{infoContainer?.BookingNro }}</td>
                    </tr>
                    <tr>
                      <td><b>{{ `${$t('label.vessel')}: ` }}</b> {{infoContainer?.VesselName ?? '' }}</td>
                      <td><b>{{ `${$t('label.voyage')}: ` }}</b> {{infoContainer?.Voyage ?? '' }}</td>
                      <td colspan="2"><b>ATA/ATD:</b> 
                        {{ DateFormat(infoContainer?.Ata) }} / 
                        {{ DateFormat(infoContainer?.Atd) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"><b>{{ `${$t('label.client')}: ` }}</b> {{ ClientName ?? '' }}</td>
                      <td><b>{{ `${$t('label.phone')}: ` }}</b> {{ infoContainer?.ClientPrimaryPhone ?? '' }}</td>
                    </tr>
                    <tr>
                      <td colspan="3"><b>{{ `${$t('label.CustomBroker')}: ` }}</b> {{ infoContainer?.CustomBrokerName ?? '' }}</td>
                      <td><b>{{ `${$t('label.phone')}: ` }}</b> {{ infoContainer?.CustomBrokerPrimaryPhone ?? '' }}</td>
                    </tr>
                    <tr>
                      <td colspan="4"><b>{{ `${$t('label.PleaseReturnEmptyEquipmentAt')}: `}}</b> {{Devolution ?? ''}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="damege-code-info text-center mt-2">
                <strong style="width: 100%;">{{ $t('label.DamageCodes') }}</strong>
                <table class="damage-code-table text-table-left">
                  <tbody>
                    <tr>
                      <th :class="DamageJson[0]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[0]?.TpDamageAbbrevEn : DamageJson[0]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[0]?.TpDamageNameEn : 
                            DamageJson[0]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[1]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[1]?.TpDamageAbbrevEn : DamageJson[1]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[1]?.TpDamageNameEn : 
                            DamageJson[1]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[2]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[2]?.TpDamageAbbrevEn : DamageJson[2]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[2]?.TpDamageNameEn : 
                            DamageJson[2]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[3]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[3]?.TpDamageAbbrevEn : DamageJson[3]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[3]?.TpDamageNameEn : 
                            DamageJson[3]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[4]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[4]?.TpDamageAbbrevEn : DamageJson[4]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[4]?.TpDamageNameEn : 
                            DamageJson[4]?.TpDamageNameEs}}
                      </td>
                      <th>{{ $t('label.Inoperative') }}</th>
                    </tr>
                    <tr>
                      <th :class="DamageJson[5]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[5]?.TpDamageAbbrevEn : DamageJson[5]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[5]?.TpDamageNameEn : 
                            DamageJson[5]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[6]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[6]?.TpDamageAbbrevEn : DamageJson[6]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[6]?.TpDamageNameEn : 
                            DamageJson[6]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[7]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[7]?.TpDamageAbbrevEn : DamageJson[7]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[7]?.TpDamageNameEn : 
                            DamageJson[7]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[8]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[8]?.TpDamageAbbrevEn : DamageJson[8]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[8]?.TpDamageNameEn : 
                            DamageJson[8]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[9]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[9]?.TpDamageAbbrevEn : DamageJson[9]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[9]?.TpDamageNameEn : 
                            DamageJson[9]?.TpDamageNameEs}}
                      </td>
                      <td class="text-center">
                        <b v-if="!FgEirContainerCondiction">X</b>
                      </td>
                    </tr>
                    <tr>
                      <th :class="DamageJson[10]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[10]?.TpDamageAbbrevEn : DamageJson[10]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[10]?.TpDamageNameEn : 
                            DamageJson[10]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[11]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[11]?.TpDamageAbbrevEn : DamageJson[11]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[11]?.TpDamageNameEn : 
                            DamageJson[11]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[12]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[12]?.TpDamageAbbrevEn : DamageJson[12]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[12]?.TpDamageNameEn : 
                            DamageJson[12]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[13]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[13]?.TpDamageAbbrevEn : DamageJson[13]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[13]?.TpDamageNameEn : 
                            DamageJson[13]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[14]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[14]?.TpDamageAbbrevEn : DamageJson[14]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[14]?.TpDamageNameEn : 
                            DamageJson[14]?.TpDamageNameEs}}
                      </td>
                      <th>{{ $t('label.operational') }}</th>
                    </tr>
                    <tr>
                      <th :class="DamageJson[15]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[15]?.TpDamageAbbrevEn : DamageJson[15]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[15]?.TpDamageNameEn : 
                            DamageJson[15]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[16]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[16]?.TpDamageAbbrevEn : DamageJson[16]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[16]?.TpDamageNameEn : 
                            DamageJson[16]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[17]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[17]?.TpDamageAbbrevEn : DamageJson[17]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[17]?.TpDamageNameEn : 
                            DamageJson[17]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[18]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[18]?.TpDamageAbbrevEn : DamageJson[18]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[18]?.TpDamageNameEn : 
                            DamageJson[18]?.TpDamageNameEs}}
                      </td>
                      <th :class="DamageJson[19]?.FgChecked ? 'FgCheckedDamage':''">
                        {{ValidateLanguage ? DamageJson[19]?.TpDamageAbbrevEn : DamageJson[19]?.TpDamageAbbrevEs}}
                      </th>
                      <td>{{ValidateLanguage ? DamageJson[19]?.TpDamageNameEn : 
                            DamageJson[19]?.TpDamageNameEs}}
                      </td>
                      <td class="text-center">
                        <b v-if="FgEirContainerCondiction">X</b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="px-0 mt-2" style="width: 100%;">
                  <img width="100%" height="240px" :src="SrcImg">
                </div>

                <table class="reefer-table text-table-center mt-2" v-if="arrIdsDamage[Page-1]&&arrIdsDamage[Page-1].length">
                  <tbody>
                    <tr>
                      <th colspan="5">{{ $t('label.damage') }}</th>
                    </tr>
                    <tr>
                      <th style="width: 5%;">N°</th>
                      <th style="width: 14%;">{{ $t('label.damageType') }}</th>
                      <th style="width: 10%;">{{ $t('label.ubication') }}</th>
                      <th style="width: 10%;">{{ $t('label.dimension') }}</th>
                      <th style="width: 61%;">{{ $t('label.observation') }}</th>
                    </tr>
                    <tr v-for="item in arrIdsDamage[Page-1]" :key="item.VisitCargoInspDamageId">
                      <td>{{ item.Nro }}</td>
                      <td>{{ $i18n.locale=='en' ? item.TpDamageNameEn : item.TpDamageNameEs }}</td>
                      <td>{{ $i18n.locale=='en' ? item.TpCargoViewNameEn : item.TpCargoViewNameEs }}</td>
                      <td>{{ item.Width && item.Height ? `${item.Width} x ${item.Height}` : '' }}</td>
                      <td>{{ item.Observation ? trimRemoveSpecialCharacters(item.Observation, 120) : '' }}</td>
                    </tr>
                  </tbody>
                </table>

                <table class="reefer-table text-table-center mt-2" v-if="arrIdsAccessories[Page-1]&&arrIdsAccessories[Page-1].length">
                  <tbody>
                    <tr>
                      <th colspan="4">{{ $t('label.specialEquipments') }}</th>
                    </tr>
                    <tr>
                      <th style="width: 5%;">N°</th>
                      <th style="width: 23%;">{{ `${$t('label.accesory')} / ${$t('label.component')} `}}</th>
                      <th style="width: 10%;">{{ $t('label.operative') }}</th>
                      <th style="width: 62%;">{{ $t('label.observation') }}</th>
                    </tr> 
                   <tr v-for="item in arrIdsAccessories[Page-1]" :key="item.TpCargoAccesoryId">
                      <td>{{ item.Nro }}</td>
                      <td>{{ $i18n.locale=='en' ? item.TpCargoAccesoryNameEn||'' : item.TpCargoAccesoryNameEs||'' }}</td>
                      <td>{{ item.FgOperative ? $t('label.yes') : 'NO' }}</td>
                      <td>{{ item.Observation ? trimRemoveSpecialCharacters(item.Observation, 125) : '' }}</td>
                    </tr>
                  </tbody>
                </table>
                
                <div class="observation mt-2">
                  <strong>{{ $t('label.MOVIMIENTO.observation') }}</strong>
                  <div class="box text-left px-2">{{ObservationUser ? trimRemoveSpecialCharacters(ObservationUser, 250) : '' }}</div>
                </div>

                <div class="data-name">
                  <table class="reefer-table text-table-center mt-2">
                    <thead>
                      <th>{{ $t('label.CarrierData') }}</th>
                      <th>{{ $t('label.CheckerData') }}</th>
                    </thead>
                    <tbody class="text-table-left">
                      <td>{{ `${$t('label.DriversReadableName')}: ` }} {{DriverName}}</td>
                      <td>{{ `${$t('label.ReadableName')}: ` }} {{OperatorName}}</td>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="corners-container">
            <p>
              {{ $t("label.page")+': '+(Page) }} {{ $t("label.of") }} {{ Pages }}
            </p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SumaryChartHold from "@/pages/visita/tabs/reporte/listas/chart/sumary-chart-hold";
import SumaryChartTon from "@/pages/visita/tabs/reporte/listas/chart/sumary-chart-ton";
import { DateFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
import DataStorage from "@/_helpers/localStorage";

//Data
function data() {
  return {
    baysTwenty: [],
    baysForty: [],
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function formatNumber(number) {
  if (number == "") return "0,00";
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace(".", ",");

  return format.replace(/\d(?=(\d{3})+\,)/g, "$&.");
}
function TurnName(Turns) {
  let _lang = this.$i18n.locale;
  let Name = _lang=='en' ? Turns.TurnNameEn : Turns.TurnNameEs;
  return Name;
}
function DateFormat(Date) {
  if (Date != null && Date != '') {
    return DateFormater.formatDateTimeWithSlash(Date);
  } else {
    return 'N/A';
  }
}

function DateFormat2(Date) {
  if (Date != null && Date != '') {
    return DateFormater.formatDateTimeWithSlashToOnlyDate(Date);
  } else {
    return 'N/A';
  }
}

function DateFormat3(Date) {
  if (Date != null && Date != '') {
    return DateFormater.formatDateTimeWithSlashToOnlyTime(Date);
  } else {
    return 'N/A';
  }
}

// function removeTags(str) {
//   if ((str===null) || (str===''))
//     return false;
//   else
//     str = str.toString();
//   return str.replace( /(<([^>]+)>)/ig, '');
// }

function validateReception() {
  return process.env.VUE_APP_EVENT_RECEPTION;
}

function validateDispatch() {
  return process.env.VUE_APP_EVENT_DISPATCH;
}

//Computeds:
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function hasVesselImg() {
  return `${this.$store.getters["connection/getBase"]}${
    this.vesselRoute ? this.vesselRoute.replace("Public/", "", null, "i") : ""
  }`;
}
function currentDateTime() {
  let today = new Date();
  let month =
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  let date = today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleCompany() {
 let CompanyName = this.user.CompanyName;
 let CompanyRif = this.user.CompanyRif;
 let BranchName = this.branch.BranchName;

  return `${CompanyName} ${CompanyRif} - ${BranchName}`;
}
function titleVessel() {
 let VesselName = this.itinerarySelected.VesselName;
 let Imo = this.itinerarySelected.Imo;
 let CountryName = this.itinerarySelected.CountryName;
 let berth = this.$t('label.berth');
 let BerthName = this.itinerarySelected.BerthName;

  return `${VesselName} ${Imo}, ${CountryName}, ${berth}: ${BerthName}`;
}
function titleFromItinerary() {
  let previousDate;
  let laterDate;
  let fecha = "";
  let voyage = this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;

  if(this.itinerarySelected.Ata!=undefined){ 
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata);
    previousDate = "ATA:"+fecha;
  }

  if(this.itinerarySelected.Ata==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta);
    previousDate = "ETA:"+fecha;
  }

  if(this.itinerarySelected.Atd!=undefined){  
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd);
    laterDate = "ATD:"+fecha;
  }

  if(this.itinerarySelected.Atd==undefined){
    fecha=DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd);
    laterDate = "ETD:"+fecha;
  }

  return `${previousDate}, ${laterDate} - ${voyage}`;
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}
function totalPages() {
  return 1;
}
function DamageJson() {
  return this.infoContainer?.DamageJson&&this.infoContainer?.DamageJson[0]?this.infoContainer?.DamageJson:[];
}

function SrcImg() {
  return this.$store.getters['connection/getBase']+(ValidateLanguage?'download/DamageContainerReportEn.jpg':'download/DamageContainerReportEs.jpg');
}

function ValidateLanguage() {
  return this.$i18n.locale=='en' ? true : false;
}

export default {
  name: "eir-report",
  props: {
    Condition: Number,
    TpVessel: String,
    CraneName: String,
    Header: Object,
    Embarked: Object,
    Movement: Object,
    Lapse: Array,
    Position: Array,
    ChartHold: Array,
    ChartTon: Array,
    itinerarySelected: Object,
    VehicleName: String,
    infoContainer: {
      type: Object,
      default: () => {}
    },
    OperatorName: String,
    ClientName: String,
    FormatedDate: String,
    TransportName: String,
    DriverName: String,
    Devolution: String,
    ObservationUser: String,
    FgEirContainerCondiction: Boolean,
    arrIdsDamage: Array,
    arrIdsAccessories: Array,
    Pages: Number,
    Preview: Boolean,
  },
  mixins: [],
  components: {
    SumaryChartHold,
    SumaryChartTon,
  },
  data,
  created,
  methods: {
    DateFormat,
    DateFormat2,
    DateFormat3,
    //removeTags,
    TurnName,
    formatNumber,
    trimRemoveSpecialCharacters,
  },
  computed: {
    currentDateTime,
    titleCompany,
    titleVessel,
    titleFromItinerary,
    hasLogo,
    hasVesselImg,
    loginName,
    totalPages,
    validateReception,
    validateDispatch,
    DamageJson,
    SrcImg,
    ValidateLanguage,
    ...mapState({
      user: (state) => state.auth.user,
      branch: state => state.auth.branch,
    }),
  },
  watch: {},
};
</script>
<style src="./pdf-styles.css"></style>